import React, { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import AddIcon from "@mui/icons-material/Add";
import {
  AppBar,
  styled,
  TextField,
  Button,
  Typography,
  Toolbar,
  IconButton,
  Stack,
} from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { Delete, Assignment } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  createTemplate,
  deleteTemplate,
  updateTemplate,
} from "../template.slice";
import CustomSnackbar from "../../../components/CustomSnackbar";
import { decodeHashData } from "../../../utils/hashing";
import EMRComponent from "../../../components/EMRComponent";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SecondaryButton = styled("button")(({ theme }) => ({
  "&": theme.typography.secondaryButton,
  [theme.breakpoints.down("sm")]: {
    padding: "5px 10px",
  },
}));

const VitalsContainer = styled("div")(({ theme }) => ({
  "&": {
    backgroundColor: theme.palette.primaryWhite,
    // marginTop: theme.spacing(4),
    padding: theme.spacing(6),
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4, 2),
    },
  },
  "& .notes-field": {
    "&.MuiFormControl-root": {
      width: "100%",
      "& > .MuiInputBase-root ": {
        minHeight: theme.spacing(43),
      },
    },
  },
  "& .textareaAutoSizeStyle": {
    height: "165px",
    minHeight: "165px",
    maxHeight: "165px",
    width: "100%",
    minWidth: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "430px",
    },
  },
}));

const EMRFooter = styled("div")(({ theme }) => ({
  "&": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.primaryBlue}`,
    backgroundColor: "#b2d6f0",
    padding: theme.spacing(2, 8),
    position: "relative",
    bottom: 0,
    zIndex: 1,
  },
  [theme.breakpoints.down("sm")]: {
    padding: "8px 5px",
  },
}));

export default function CreateTempleteDialog({
  searchTemplate,
  setSearchTemplate,
  templateId,
  open,
  setOpen,
  edit,
  setEdit,
  isRender,
  setIsRender,
}) {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [snackbarStatus, setSnackbarStatus] = useState("error");
  const [templateName, setTemplateName] = useState("");
  const dispatch = useDispatch();
  const emrValueRef = useRef();
  const resetEMRFormRef = useRef();
  const doctorId = decodeHashData("doc_id");

  const handleClearState = () => {
    setEdit(false);
    setTemplateName("");
    resetEMRFormRef?.current?.resetEMRForm()
  };

  const saveEMR = () => {
    setShowLoader(true);
    const pmr_request = emrValueRef?.current?.formattedValues();
    const payload = {
      user_id: decodeHashData("user_id"),
      template_name: templateName,
      formData: { ...pmr_request },
    };

    dispatch(createTemplate(payload)).then((res) => {
      if (res?.error && Object.keys(res?.error)?.length > 0) {
        setErrorMessage("Something went wrong while saving the details");
        setShowSnackbar(true);
        setSnackbarStatus("error");
        setShowLoader(false);
        return;
      } else {
        console.log("Details saved successfully");
        setIsRender(!isRender);
        setErrorMessage("Details saved successfully");
        setShowSnackbar(true);
        setSearchTemplate(null);
        handleClearState();
        setSnackbarStatus("success");
        setShowLoader(false);
        setOpen(false);
      }
    });
  };
  const saveEditEMR = () => {
    setShowLoader(true);
    const pmr_request = emrValueRef?.current?.formattedValues();
    const payload = {
      template_id: templateId,
      formData: { ...pmr_request },
    };

    dispatch(updateTemplate(payload)).then((res) => {
      if (res?.error && Object.keys(res?.error)?.length > 0) {
        setErrorMessage("Something went wrong while updated the details");
        setShowSnackbar(true);
        setSnackbarStatus("error");
        setShowLoader(false);
        return;
      } else {
        console.log("Details updated successfully");
        setIsRender(!isRender);
        setErrorMessage("Details updated successfully");
        setShowSnackbar(true);
        setSnackbarStatus("success");
        setSearchTemplate(null);
        handleClearState();
        setShowLoader(false);
        setOpen(false);
      }
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
    if (!edit) {
      setSearchTemplate(null);
      handleClearState();
    }
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };
  let TemplateActionName;
  TemplateActionName = edit ? "Edit" : "Create";

  const handleDeleteTemplate = () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete the template?"
    );
    if (!confirmDelete) {
      return;
    } else {
      const payload = {
        template_id: searchTemplate?.id,
      };
      dispatch(deleteTemplate(payload)).then((res) => {
        setOpen(false);
        setSearchTemplate(null);
        handleClearState();
        setIsRender(!isRender);
        setErrorMessage("Template deleted successfully");
        setSnackbarStatus("success");
        setShowSnackbar(true);
      });
    }
  };
  return (
    <>
      <CustomSnackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        status={snackbarStatus}
        message={errorMessage}
      />
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          width: "250px",
          textTransform: "none",
          fontSize: "1.2rem",
          border: "2px solid",
        }}
        startIcon={<AddIcon sx={{ height: "2rem" }} />}
      >
        Create Template
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Stack sx={{ flex: 1 }}>
              <Typography variant="h6" sx={{ color: "white" }}>
                {TemplateActionName} Template
              </Typography>
            </Stack>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {/* <EMRFormWrapper> */}
          {/* <EMRFormInnerWrapper> */}
            <VitalsContainer>
              <Stack mb={2}>
                <TextField
                  placeholder="Enter Template Name"
                  value={templateName || searchTemplate?.template_name}
                  onChange={(e) => setTemplateName(e.target.value)}
                  required
                  sx={{ maxWidth: "400px" }}
                  disabled={edit}
                />
                {templateName.length <= 0 && !edit && (
                  <p style={{ color: "red" }}>
                    *Its required to enter a template name
                  </p>
                )}
              </Stack>
              </VitalsContainer>
              <EMRComponent 
                searchTemplate={searchTemplate?.template_json}
                setSearchTemplate={setSearchTemplate}
                emrValueRef={emrValueRef}
                resetEMRFormRef={resetEMRFormRef}
                doctorId={doctorId}
              />
          
          {/* </EMRFormInnerWrapper> */}
          <EMRFooter style={{ position: "sticky", bottom: 0 }}>
            {edit && (
              <>
                <SecondaryButton
                  autoFocus
                  color="inherit"
                  onClick={handleDeleteTemplate}
                >
                  Delete
                </SecondaryButton>
              </>
            )}
            {!edit ? (
              <Button
                variant="contained"
                onClick={saveEMR}
                disabled={templateName.length > 0 ? false : true}
              >
                Save
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={saveEditEMR}
                disabled={
                  templateName.length > 0
                    ? false
                    : true || searchTemplate?.template_name.length > 0
                    ? false
                    : true
                }
              >
                Save Changes
              </Button>
            )}
          </EMRFooter>
        {/* </EMRFormWrapper> */}
      </Dialog>
    </>
  );
}