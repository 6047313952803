import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest } from "../../utils/request";
import { apis } from "../../utils/apis";

export const registerExternalPatient = createAsyncThunk(
  "doctorRegistration/registerDoctor",
  async (payload) => {
    const response = await apiRequest(
      "GET",
      `${apis?.readPatientByMobile}?mobile_number=${payload.mobile_number}&doc_id=${payload.doc_id}`
    );
    return response;
  }
);

export const registerExternalHosiptalPatient = createAsyncThunk(
  "registerExternalHosiptalPatient",
  async (payload) => {
    const response = await apiRequest(
      "POST",
      apis?.registerExternalPatient,
      payload
    );
    return response;
  }
);

export const listAllExternalPatient = createAsyncThunk(
  "registerExternalHosiptalPatient",
  async (payload) => {
    const response = await apiRequest(
      "GET",
      `${apis?.listAllExternalPatient}?doc_id=${payload?.doc_id}`,
      payload
    );
    return response;
  }
);

const externalPatientRegistrationSlice = createSlice({
  name: "doctorRegistration",
  initialState: {
    loading: false,
    error: null,
    registerExternalPatient: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerExternalPatient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerExternalPatient.fulfilled, (state, action) => {
        state.loading = false;
        state.registeringDoctor = action.payload;
      })
      .addCase(registerExternalPatient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to register doctor";
      });
  },
});

export default externalPatientRegistrationSlice.reducer;
