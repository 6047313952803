import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest } from "../../utils/request";
import { apis } from "../../utils/apis";

export const createTemplate = createAsyncThunk(
  "doctorRegistration/registerDoctor",
  async (payload) => {
    const response = await apiRequest(
      "POST",
      `${apis?.createTemplate}?template_name=${payload?.template_name}&user_id=${payload?.user_id}`,
      payload?.formData
    );
    return response;
  }
);
export const getTemplateDetails = createAsyncThunk(
  "doctorRegistration/registerDoctor",
  async (payload) => {
    const response = await apiRequest(
      "GET",
      `${apis?.getTemplateDetail}?template_name=${payload?.template_name}&user_id=${payload?.user_id}`
    );
    return response;
  }
);
export const getAllTemplates = createAsyncThunk(
  "doctorRegistration/registerDoctor",
  async (payload) => {
    const response = await apiRequest(
      "GET",
      `${apis?.getAllTemplates}?user_id=${payload?.user_id}`
    );
    return response;
  }
);
export const getTemplateResponse = createAsyncThunk(
  "doctorRegistration/registerDoctor",
  async (payload) => {
    const response = await apiRequest(
      "GET",
      `${apis?.getTemplateResponse}?template_id=${payload?.template_id}`
    );
    return response;
  }
);
export const deleteTemplate = createAsyncThunk(
  "doctorRegistration/registerDoctor",
  async (payload) => {
    const response = await apiRequest(
      "DELETE",
      `${apis?.deleteTemplate}?template_id=${payload?.template_id}`
    );
    return response;
  }
);
export const updateTemplate = createAsyncThunk(
  "doctorRegistration/registerDoctor",
  async (payload) => {
    const response = await apiRequest(
      "POST",
      `${apis?.updateTemplate}?template_id=${payload?.template_id}`,
      payload?.formData
    );
    return response;
  }
);

const templateRegistrationSlice = createSlice({
  name: "doctorRegistration",
  initialState: {
    loading: false,
    error: null,
    createTemplate: {},
    getTemplateDetails: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createTemplate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.registeringDoctor = action.payload;
      })
      .addCase(createTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to register doctor";
      })
      .addCase(getTemplateDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTemplateDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.registeringDoctor = action.payload;
      })
      .addCase(getTemplateDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to register doctor";
      })
      .addCase(getAllTemplates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllTemplates.fulfilled, (state, action) => {
        state.loading = false;
        state.registeringDoctor = action.payload;
      })
      .addCase(getAllTemplates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to register doctor";
      });
  },
});

export default templateRegistrationSlice.reducer;
