import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import InfoIcon from "@mui/icons-material/Info";
import EmailIcon from "@mui/icons-material/Email";
import WorkIcon from "@mui/icons-material/Work";
import LanguageIcon from "@mui/icons-material/Language";

const VirtualCardAman = () => {
  const [viewMore, setViewMore] = useState(false);

  const sampleData = {
    name: "Aman Khandelwal",
    userImg:
      "https://media.licdn.com/dms/image/v2/D4D03AQEq7WLQUvndNA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1708001408224?e=1730332800&v=beta&t=YoElsTYoXmTPGVZro1R0qL0ePsoFlaw1XxR8qI5gU30",
    designation: "CEO, CliniQ360",
    about:
      "Aman Khandelwal, CEO of CliniQ360, is dedicated to bridging the gap between payors, providers, and patients through a unified digital platform. His vision is to create a seamless healthcare ecosystem where all stakeholders can collaborate effortlessly, ensuring better outcomes for everyone involved.",
    mobile_number: "9823172692",
    email: "aman.khandelwal@cliniq360.com",
    workPlace: "CliniQ360",
    website: "https://cliniq360.com",
  };

  const handleVcardFunc = () => {
    const vCardString = `
    BEGIN:VCARD
    VERSION:3.0
    FN:${sampleData.name}
    ORG:${sampleData.workPlace}
    PHOTO;TYPE=JPEG;VALUE=URI:${sampleData.userImg}
    TEL;TYPE=WORK,VOICE:${sampleData.mobile_number}
    TITLE:${sampleData.designation}
    URL:${sampleData.website}
    END:VCARD
    `.trim();

    const blob = new Blob([vCardString], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${sampleData.name}.vcf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const onHandleClick = () => {
    window.open(sampleData.website, "website");
  };

  const infoBoxStyles = {
    display: "flex",
    margin: "10px",
    padding: "5px",
  };

  const iconBoxStyles = {
    width: "32px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          maxWidth: "100%",
          width: "400px",
          boxShadow: "20px",
          borderRadius: "5px",
          margin: "10px 10px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#1976d2",
            width: "auto",
            height: "200px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              height: "100px",
              width: "100px",
              backgroundColor: "white",
              borderRadius: "50%",
              backgroundImage: `url(${sampleData.userImg})`,
              backgroundSize: "cover",
              marginBottom: "10px",
            }}
          ></Box>
          <Typography variant="h3" color={"white"}>
            {sampleData.name}
          </Typography>
          <Typography variant="h3" fontSize={"1rem"} color={"white"}>
            {sampleData.designation}
          </Typography>
        </Box>

        <Box sx={infoBoxStyles}>
          <Box sx={iconBoxStyles}>
            <InfoIcon sx={{ fontSize: "2rem" }} />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "0.8rem", marginLeft: "10px" }}>
              {!viewMore ? (
                <>
                  {sampleData?.about.slice(0, 250)}...
                  <span
                    onClick={() => setViewMore(true)}
                    style={{ cursor: "pointer", color: "#1976d2" }}
                  >
                    View More
                  </span>
                </>
              ) : (
                <>
                  {sampleData?.about}
                  <span
                    onClick={() => setViewMore(false)}
                    style={{ cursor: "pointer", color: "#1976d2" }}
                  >
                    Show Less
                  </span>
                </>
              )}
            </Typography>
          </Box>
        </Box>

        <Box sx={infoBoxStyles}>
          <Box sx={iconBoxStyles}>
            <LocalPhoneIcon sx={{ fontSize: "2rem" }} />
          </Box>
          <Box>
            <Typography
              sx={{ fontSize: "1rem", marginLeft: "10px", cursor: "pointer" }}
            >
              {sampleData.mobile_number}
            </Typography>
            <Typography sx={{ fontSize: "0.7rem", marginLeft: "10px" }}>
              Mobile Number
            </Typography>
          </Box>
        </Box>

        <Box sx={infoBoxStyles}>
          <Box sx={iconBoxStyles}>
            <EmailIcon sx={{ fontSize: "2rem" }} />
          </Box>
          <Box>
            <Typography
              sx={{ fontSize: "1rem", marginLeft: "10px", cursor: "pointer" }}
            >
              {sampleData.email}
            </Typography>
            <Typography sx={{ fontSize: "0.7rem", marginLeft: "10px" }}>
              Email
            </Typography>
          </Box>
        </Box>

        <Box sx={infoBoxStyles}>
          <Box sx={iconBoxStyles}>
            <WorkIcon sx={{ fontSize: "2rem" }} />
          </Box>
          <Box>
            <Typography
              sx={{ fontSize: "1rem", marginLeft: "10px", cursor: "pointer" }}
            >
              {sampleData.workPlace}
            </Typography>
            <Typography sx={{ fontSize: "0.7rem", marginLeft: "10px" }}>
              {sampleData.designation}
            </Typography>
          </Box>
        </Box>

        <Box sx={infoBoxStyles}>
          <Box sx={iconBoxStyles}>
            <LanguageIcon sx={{ fontSize: "2rem" }} />
          </Box>
          <Box>
            <Typography
              onClick={onHandleClick}
              sx={{ fontSize: "1rem", marginLeft: "10px", cursor: "pointer" }}
            >
              {sampleData.website}
            </Typography>
            <Typography sx={{ fontSize: "0.7rem", marginLeft: "10px" }}>
              Website
            </Typography>
          </Box>
        </Box>

        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Button
              onClick={handleVcardFunc}
              sx={{ width: "90%" }}
              variant="contained"
            >
              Save
            </Button>
          </Grid>
          <Typography sx={{ marginTop: "5px" }}>©2023 cliniQ360</Typography>
        </Grid>
      </Box>
    </Box>
  );
};

export default VirtualCardAman;
