import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import {
  registerExternalHosiptalPatient,
  registerExternalPatient,
} from "../../pages/ExternalHip/externalPatientRegistrationSlice.slice";
import { decodeHashData } from "../../utils/hashing";
import { useNavigate } from "react-router-dom";

const ExternalHospitalForm = () => {
  const navigate = useNavigate();
  const doc_id = decodeHashData("doc_id");
  const [patientDataId, setPatientDataId] = useState(null);
  const [hospitalName, setHospitalName] = useState([]);
  const [isApiCall, setIsApiCall] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const formatDob = (date) => format(new Date(date), "yyyy-MM-dd");

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    mobile_number: "",
    name: "",
    hospital_name: "",
    consultation_date: formatDob(new Date()),
    followup_date: "",
  });
  const [mobileNumberError, setMobileNumberError] = useState("");

  const handleMobileNumber = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      mobile_number: value,
    }));
    if (value.length > 10) {
      setMobileNumberError("Mobile number cannot be more than 10 digits");
    } else if (value.length === 10) {
      setIsApiCall(true);
      setMobileNumberError("");
    } else {
      setMobileNumberError("");
    }
  };

  useEffect(() => {
    if (isApiCall) {
      handleSearchByMobile();
    }
  }, [isApiCall]);

  const handleSearchByMobile = () => {
    const payload = {
      ...formData,
      doc_id,
    };
    dispatch(registerExternalPatient(payload)).then((res) => {
      setHospitalName(res?.payload?.hospital_list);
      setPatientDataId(res?.payload?.patient_data?.id);
      setFormData((prev) => ({
        ...prev,
        name: res?.payload?.patient_data?.name || "",
        hospital_name: res?.payload?.patient_data?.hospital_name || "",
      }));
      setActiveStep(activeStep + 1);
      setIsApiCall(false); // Reset the API call flag
    });
  };

  const handleFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleAutoCompleteChange = (e, newValue) => {
    setFormData((prevValues) => ({
      ...prevValues,
      hospital_name: newValue || "",
    }));
  };

  const onhandleSubmit = (e) => {
    e.preventDefault();
    let payload = {
      ...formData,
      doc_id,
    };
    if (patientDataId) {
      payload.id = patientDataId;
    }
    console.log(payload);
    dispatch(registerExternalHosiptalPatient(payload)).then((res) => {
      console.log(res?.payload);
      setActiveStep(0);
      setFormData({
        mobile_number: "",
        name: "",
        hospital_name: "",
        consultation_date: formatDob(new Date()),
        followup_date: "",
      });
      navigate("/external-hip");
    });
  };

  return (
    <Box sx={{ padding: 3, mt: 5 }}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h3">
            <b>Register External Patient</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 3 }}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack alignItems={"center"} direction={"row"} gap={2}>
              <TextField
                name="mobile_number"
                label="Mobile Number"
                value={formData.mobile_number}
                onChange={handleMobileNumber}
                InputLabelProps={{ shrink: true }}
                required
                sx={{ width: "300px" }}
                error={!!mobileNumberError}
                helperText={mobileNumberError}
              />
            </Stack>
          </form>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={activeStep > 0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography variant="h3">
            <b>Register Patient Information</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={onhandleSubmit}>
            <Grid container gap={2} spacing={2}>
              <Grid item xs={12} md={3.9}>
                <TextField
                  name="name"
                  label="Patient Name"
                  value={formData.name}
                  onChange={handleFormData}
                  InputLabelProps={{ shrink: true }}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3.9}>
                <TextField
                  sx={{ width: "100%" }}
                  label="Consultation Date"
                  type="date"
                  inputProps={{
                    min: format(new Date(), "yyyy-MM-dd"), // Set min date to the current date
                  }}
                  value={formData.consultation_date}
                  name="consultation_date"
                  onChange={handleFormData}
                />
              </Grid>
              <Grid item xs={12} md={3.9}>
                <TextField
                  sx={{ width: "100%" }}
                  label="Follow-up Date"
                  type="date"
                  value={formData.followup_date}
                  InputLabelProps={{ shrink: true }}
                  name="followup_date"
                  onChange={handleFormData}
                />
              </Grid>
              <Grid item xs={12} md={3.9}>
                <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  clearOnBlur
                  value={formData.hospital_name}
                  onChange={handleAutoCompleteChange}
                  inputValue={formData.hospital_name}
                  onInputChange={handleAutoCompleteChange}
                  options={hospitalName?.map((option) => option)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Hospital Name"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}
            >
              <Button type="submit" variant="contained">
                Submit Form
              </Button>
            </Box>
          </form>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ExternalHospitalForm;
