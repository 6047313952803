import React, { useState } from "react";
import { Button, Grid, styled, TextareaAutosize, TextField, Typography } from "@mui/material";
import { contactForm } from "./middleware";
import CustomSnackbar from "../../components/CustomSnackbar";
import CustomLoader from "../../components/CustomLoader";

const ContactUsWrapper = styled("div")(({theme}) => ({
  marginTop: "-40px",
  "&": {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      marginBottom: "20px"
  },
  "& form": {
    marginTop: "5px",
    width: "60%",
    borderRadius: "10px",
    padding: "20px",
    backgroundColor: "#fff",
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
  },
  "& form .MuiTypography-root": {
      textTransform: "uppercase",
      marginBottom: "5px",
  }
}));

const ContactUsPage = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState("error");
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    email_id: "",
    role: "",
    message: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  }

  const handleSubmit = () => {
    setShowLoader(true);
    contactForm(formData).then((res) => {
      if (res?.error && Object.keys(res?.error)?.length > 0) {
        setSnackbarStatus("error");
        setErrorMessage("Something went wrong");
        setShowSnackbar(true);
        setShowLoader(false);
        return;
      } else {
        setSnackbarStatus("success");
        setErrorMessage("Details saved successfully");
        setShowSnackbar(true);
        setShowLoader(false);
      }
    });
  }

  const onSnackbarClose = () => {
    setShowSnackbar(false);
  };
  
  return (
    <ContactUsWrapper>
      <CustomLoader
        open={showLoader}
      />
       <CustomSnackbar
        message={errorMessage || "Something went wrong"}
        open={showSnackbar}
        status={snackbarStatus}
        onClose={onSnackbarClose}
      />
      <p style={{ fontSize: "26px", fontWeight: "600", textTransform: "uppercase", marginTop: "5px" }}>
        Contact Us
      </p>
      <form>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography>First Name</Typography>
            <TextField
                onChange={handleChange}
                fullWidth
                variant="outlined"
                placeholder="Enter Your fullname"
                value={formData.first_name}
                required
                id="first_name"
                name="first_name"
                sx={{ marginBottom: "10px" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Last Name</Typography>
            <TextField
                onChange={handleChange}
                fullWidth
                variant="outlined"
                placeholder="Enter First Name"
                value={formData.last_name}
                required
                id="last_name"
                name="last_name"
                sx={{ marginBottom: "10px" }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography>Mobile Number</Typography>
            <TextField
                onChange={handleChange}
                fullWidth
                variant="outlined"
                placeholder="Enter mobile number"
                value={formData.mobile_number}
                required
                id="mobile_number"
                name="mobile_number"
                sx={{ marginBottom: "10px" }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography>Email</Typography>
            <TextField
                onChange={handleChange}
                fullWidth
                variant="outlined"
                placeholder="Enter Last Name"
                value={formData.email_id}
                required
                id="email_id"
                name="email_id"
                sx={{ marginBottom: "10px" }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography>Role</Typography>
            <TextField
                onChange={handleChange}
                fullWidth
                variant="outlined"
                placeholder="Your Role"
                value={formData.role}
                required
                id="role"
                name="role"
                sx={{ marginBottom: "10px" }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography>Message</Typography>
            <TextField
                // className="textareaAutoSizeStyle"
                // minRows={7}
                // maxRows={7}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                placeholder="Your Message"
                value={formData.message}
                required
                id="message"
                name="message"
                sx={{ marginBottom: "10px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
       
      </form>
    </ContactUsWrapper>
    )
}

export default ContactUsPage;