import React, { useEffect, useState } from "react";
import MyTable from "../../components/TableComponent";
import { Typography, styled, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { convertDateFormat } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../components/CustomLoader";
import { listAllExternalPatient } from "./externalPatientRegistrationSlice.slice";
import { decodeHashData } from "../../utils/hashing";

const ListWrapper = styled("div")(({ theme }) => ({
  "&": {
    padding: "20px 10px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  ".patientList-title-wrapper": {
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  ".patientList-heading": {
    "&.MuiTypography-root": theme.typography.h1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },
  ".patientList-desc": theme.typography.h2,
  ".table-class": {
    "&.MuiPaper-root": {
      borderRadius: "0",
      boxShadow: "none",
    },
    "& .MuiTableHead-root": {
      "& > tr >th": {
        "&": theme.typography.h3,
        [theme.breakpoints.down("md")]: {
          "&": theme.typography.body2,
        },
        padding: theme.spacing(4),
      },
    },
    "& .MuiTableBody-root": {
      "& > tr >td": {
        "&": theme.typography.body1,
        cursor: "pointer",
        padding: theme.spacing(4),
      },
    },
  },
  ".search-class": {
    "&.MuiFormControl-root": {
      flex: 0.3,
      padding: 0,
      margin: 0,
      [theme.breakpoints.down("sm")]: {
        flex: "1 ",
      },
      "& .MuiInputBase-input": {
        padding: "12px 16px",
        backgroundColor: theme.palette.primaryWhite,
      },
      "& .MuiButtonBase-root .MuiSvgIcon-root": {
        color: theme.palette.secondaryBlue,
      },
    },
  },
  ".header-btn": {
    "&.MuiButtonBase-root": theme.typography.primaryButton,
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
  },
}));

const consentTableStyle = {
  backgroundColor: "#f1f1f1",
  ".linkTypography": {
    textTransform: "none !important",
  },
};

const ExternalHip = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const doc_id = decodeHashData("doc_id");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const payload = {
      doc_id,
    };
    dispatch(listAllExternalPatient(payload)).then((res) => {
      const allExpiryData = res?.payload;
      const formattedExternalList = allExpiryData?.map((item) => {
        const created_at = convertDateFormat(item?.created_at, "dd-MM-yyyy");
        const updated_at = convertDateFormat(item?.updated_at, "dd-MM-yyyy");
        return {
          createdAt: created_at,
          updatedAt: updated_at,
          ...item,
        };
      });
      setPatientData(formattedExternalList);
    });
  }, [dispatch, doc_id]);

  const columns = [
    {
      key: "name",
      header: "Patient Name",
    },
    {
      key: "hospital_name",
      header: "Hospital Name",
    },
    {
      key: "createdAt",
      header: "Created At",
    },
    {
      key: "updatedAt",
      header: "Updated At",
    },
  ];

  return (
    <ListWrapper>
      <CustomLoader open={showLoader} />
      <div className="patientList-title-wrapper">
        <div>
          <Typography className="patientList-heading">External Hips</Typography>
          <Typography className="patientList-desc">
            Manage your patient information
          </Typography>
        </div>
        <Button
          variant="contained"
          className="header-btn"
          onClick={() => navigate("/external-hip-form")}
        >
          Register New Patient
        </Button>
      </div>
      <div className="table-container">
        {patientData?.length > 0 && (
          <MyTable
            columns={columns}
            data={patientData}
            tableClassName="table-class"
            consentTableStyle={consentTableStyle}
            showSearch={true}
          />
        )}
      </div>
    </ListWrapper>
  );
};

export default ExternalHip;
