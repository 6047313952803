import React, { useEffect, useState } from "react";
import { ReactMediaRecorder } from "react-media-recorder-2";
import { useDispatch } from "react-redux";
import {
  AIFeedback,
  recorderAnalysis,
  uploadAudio,
} from "../../pages/DoctorPage/EMRPage/EMRPage.slice";
import {
  Box,
  Button,
  Card,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CustomizedSummaryDialog from "../RecordedPatientDataDialog";
import Accordion from "@mui/material/Accordion";
import { keyframes, styled } from "@mui/material/styles";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import toast, { Toaster } from "react-hot-toast";
import { set } from "date-fns";
import AIIcon from "../../assets/icons/AiIcon.png";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import sittingArr from "../../assets/icons/sitting-arr.png";
import CustomSnackbar from "../CustomSnackbar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import CustomLoader from "../CustomLoader";
import { decodeHashData, encodeHashData } from "../../utils/hashing";

const NumBars = window?.innerWidth < 768 ? 20 : 30;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const ellipsisAnimation = keyframes`
  0%, 100% {
    content: '.';
  }
  33% {
    content: '..';
  }
  66% {
    content: '...';
  }
`;

const DotsAnimationContainer = styled("span")(({ theme }) => ({
  "&::after": {
    content: '"."',
    display: "inline-block",
    width: "1em",
    animation: `${ellipsisAnimation} 1.5s infinite steps(1, end)`,
  },
}));

const RecordedSummaryContainer = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  border: "1px solid #ccc",
  borderRadius: "5px",
}));

const AudioControlPanel = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  border: "5px solid ",
  borderRadius: "50px",
  width: "65%",

  animation: "borderAnimation 3s infinite",

  [theme.breakpoints.down("md")]: {
    width: "100%",
    margin: theme.spacing(1),
  },
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(1),
    minHeight: "100px",
  },

  "@keyframes borderAnimation": {
    "0%": {
      borderColor: "#000000",
    },
    "25%": {
      borderColor: "#333333",
    },
    "50%": {
      borderColor: "#666666",
    },
    "75%": {
      borderColor: "#999999",
    },
    "100%": {
      borderColor: "#CCCCCC",
    },
  },
}));

const shadesOfGray = ["#000000", "#333333", "#666666", "#999999", "#CCCCCC"];

const generateBarStyles = () => {
  const styles = {};
  for (let i = 0; i < NumBars; i++) {
    styles[`&:nth-of-type(${i + 1})`] = {
      animationDelay: `${(i % 10) * 0.1}s`,
      backgroundColor: shadesOfGray[i % 5],
    };
  }
  return styles;
};

const Bar = styled("div")(({ theme }) => ({
  width: "4px",
  height: "100%",
  margin: "0 2px",
  backgroundColor: theme.palette.primary.main,
  animation: "wave 2s infinite",
  animationTimingFunction: "linear",
  ...generateBarStyles(),
  "@keyframes wave": {
    "0%": { height: "20%" },
    "50%": { height: "100%" },
    "100%": { height: "20%" },
  },
}));

const WaveAnimationContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  height: "50px",
});

const WaveAnimation = () => (
  <WaveAnimationContainer>
    {[...Array(NumBars)].map((_, i) => (
      <Bar key={i} />
    ))}
  </WaveAnimationContainer>
);

const RecorderComponent = ({
  summaryContent,
  setSummaryContent,
  clearForm,
  responseStructure,
  setResponseStructure,
  pmrSummary,
  setIsTypeAudio
}) => {
  const [openSummary, setOpenSummary] = useState(false);
  // const [summaryContent, setSummaryContent] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [response, setResponse] = useState("");
  const [formData, setFormData] = useState();
  const [openResDialog, setOpenResDialog] = useState(false);
  const [time, setTime] = useState(0); // Time in seconds
  const [isActive, setIsActive] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const [open, setOpen] = useState(false);
  const [timeStamp, setTimeStamp] = useState();
  const [transcriptionModel, setTranscriptionModel] = useState("OPENAI");
  const [languageModel, setLanguageModel] = useState("gpt-4-turbo");
  const [isSOAPresponse, setIsSOAPresponse] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const hospital = decodeHashData("selectedHospital");

  // Created Toast Notification
  const notify = () =>
    toast.custom(
      <>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            width: { xs: "80%", sm: "60%", md: "40%", lg: "20%" },
            backgroundColor: "#f8f8f8",
            position: "fixed",
            top: "55px",
            right: 2,
          }}
        >
          <Box
            sx={{
              height: "100px",
              width: "300px",
              backgroundImage: `url(${sittingArr})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              marginRight: "10px",
            }}
          />
          <Typography>
            It is advised to sit or communicate near the microphone or laptop.
          </Typography>
        </Stack>
      </>,
      {
        duration: 4000,
      }
    );

  // useEffect(() => {
  //   if (PmrSummary) {
  //     if (PmrSummary[0]?.[1]?.summary) {
  //       setSummaryContent(PmrSummary);
  //     }
  //   }
  // }, [PmrSummary]);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else if (!isActive && time !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const formatTime = (seconds) => {
    const getSeconds = `0${seconds % 60}`.slice(-2);
    const minutes = Math.floor(seconds / 60);
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(minutes / 60)}`.slice(-2);

    return `${getHours}:${getMinutes}:${getSeconds}`;
  };

  const [resFeedData, setResFeedData] = useState({
    feeback_reason: "",
    feedback_comment: "",
  });
  const handleStopRecording = async (mediaBlobUrl) => {
    try {
      setShowLoader(true);
      setIsRecording(false);
      setShowSkeleton(true);
      setIsRegenerating(true);
      const response = await fetch(mediaBlobUrl);
      const audioFile = await response.blob();
      const formDataObj = new FormData();
      formDataObj.append("audio_file", audioFile, "recording.mp3");
      setFormData(formDataObj);
      let payload;
      if (hospital?.hip_id === "123123") {
        payload = {
          pmr_id: decodeHashData("pmrID"),
          patient_id: decodeHashData("ID"),
          audio_file: formDataObj,
          regenerate: false,
          transcriptionModel: transcriptionModel,
          languageModel: languageModel,
          responseStructure: responseStructure,
        };
      } else {
        payload = {
          pmr_id: decodeHashData("pmrID"),
          patient_id: decodeHashData("ID"),
          audio_file: formDataObj,
          regenerate: false,
        };
      }
      dispatch(recorderAnalysis(payload))
        .then((res) => {
          const data = res?.payload?.data;
          if (res?.error && Object.keys(res?.error)?.length > 0) {
            setIsError(true);
            setShowLoader(false);
            setIsRegenerating(false);
            return;
          }
          if (data) {
            setIsTypeAudio(true);
            setSummaryContent(data);
            encodeHashData("timeStamp", data?.transcript_timestamp);
            setTimeStamp(data?.transcript_timestamp);
            setShowSkeleton(false);
            setIsRegenerating(false);
            setIsError(false);
            setShowLoader(false);
            const dataUrl = res?.payload?.data?.audio_file_path;
            if (dataUrl) {
              const payload = {
                audio_file_path: dataUrl,
              };
              dispatch(uploadAudio(payload)).then((res) => {
                console.log("uploading audio");
              });
            }
          } else {
            setShowLoader(false);
            setIsRegenerating(false);
          }
        })
        .catch((err) => {
          console.log("error occured ", err);
          setIsError(true);
        });
    } catch (error) {
      console.error("Error handling mediaBlobUrl:", error);
    }
  };

  const handleRegenerateAudio = () => {
    setIsRegenerating(true);
    setShowSkeleton(true);
    setShowLoader(true);
    try {
      let payload;
      if (hospital?.hip_id === "123123") {
        payload = {
          pmr_id: decodeHashData("pmrID"),
          patient_id: decodeHashData("ID"),
          regenerate: true,
          translate: true,
          transcript_timestamp: timeStamp,
          audio_file: formData,
          transcriptionModel: transcriptionModel,
          languageModel: languageModel,
          responseStructure: responseStructure,
        };
      } else {
        payload = {
          pmr_id: decodeHashData("pmrID"),
          patient_id: decodeHashData("ID"),
          regenerate: true,
          translate: true,
          transcript_timestamp: timeStamp,
          audio_file: formData,
        };
      }
      clearForm();
      dispatch(recorderAnalysis(payload))
        .then((res) => {
          if (res?.error && Object.keys(res?.error)?.length > 0) {
            setIsError(true);
            setShowLoader(false);
            setIsRegenerating(false);
            return;
          }
          const data = res?.payload?.data;
          setIsTypeAudio(true);
          setSummaryContent(data);
          setShowSkeleton(false);
          setIsRegenerating(false);
          setIsError(false);
          setShowLoader(false);
          const dataUrl = res?.payload?.data?.audio_file_path;
          if (dataUrl) {
            const payload = {
              audio_file_path: dataUrl,
            };
            dispatch(uploadAudio(payload)).then((res) => {
              console.log("uploading audio");
            });
          }
        })
        .catch((err) => {
          console.log("error occured ", err);
          setIsError(true);
        });
    } catch (error) {
      console.error("Error handling mediaBlobUrl:", error);
    }
  };

  const handleReset = () => {
    setIsActive(false);
    setTime(0);
  };

  const handlePositiveFeedBack = () => {
    const payload = {
      pmr_id: decodeHashData("pmrID"),
      transcript_timestamp: timeStamp,
      summary_feedback: true,
    };
    dispatch(AIFeedback(payload)).then((res) => {
      setResponse("true");
      console.log("uploading audio");
      // setOpen(true);
    });
  };

  const handleResponse = (event) => {
    const { name, value } = event.target;
    setResFeedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenResDialog(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleNegativeFeedBack();
    handleClose();
  };

  const handleNegativeFeedBack = () => {
    const payload = {
      pmr_id: decodeHashData("pmrID"),
      summary_feedback: false,
      transcript_timestamp: timeStamp,
      summary_feedback_category: resFeedData.feeback_reason,
      summary_feedback_comments: resFeedData.feedback_comment,
    };
    dispatch(AIFeedback(payload)).then((res) => {
      console.log("uploading audio");
      setResponse("false");
    });
  };

  const handleLanguageModelChange = (event) => {
    setLanguageModel(event.target.value);
    if (event.target.value === "gpt-4-turbo") setTranscriptionModel("OPENAI");
    else setTranscriptionModel("GROQ");
  };

  const handleResponseStructure = (event) => {
    setIsSOAPresponse(event.target.checked);
    if (event.target.checked === true) setResponseStructure("PMR");
    else if (event.target.checked === false) setResponseStructure("SOAP");
  };

  // useEffect(() => {
  //   console.log(pmrSummary);
  // }, [pmrSummary]);

  return (
    <>
      <Toaster />
      <CustomLoader open={showLoader} />
      <CustomSnackbar
        open={open}
        onClose={handleClose}
        status={"success"}
        message={"Thank You For Your Feedback"}
      />
      <ReactMediaRecorder
        audio
        render={({
          status,
          startRecording,
          stopRecording,
          pauseRecording,
          resumeRecording,
          mediaBlobUrl,
        }) => (
          <>
            <Stack>
              <Accordion
                defaultExpanded
                sx={{
                  padding: "0px 10px 10px 5px",
                  marginBottom: "0 !important",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ flexGrow: 1 }}
                  >
                    <img
                      src={AIIcon}
                      alt="AI"
                      style={{ height: "25px", marginRight: "10px" }}
                    />
                    <Typography
                      variant="h3"
                      fontWeight={700}
                      color={"#0089E9"}
                      fontSize={"1rem"}
                    >
                      CliniQ Note Assist
                    </Typography>
                  </Stack>
                  <Box
                    onClick={() => {
                      setOpenSummary(true);
                    }}
                  >
                    {Object.keys(summaryContent).length > 0 && (
                      <Typography
                        variant="h3"
                        marginRight={"30px"}
                        color={"#0089E9"}
                        fontWeight={700}
                        fontSize={"1rem"}
                        sx={{ textDecoration: "underline" }}
                      >
                        Insights
                      </Typography>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {!showSkeleton &&
                  (Object.keys(summaryContent).length > 0 || pmrSummary) ? (
                    <Stack
                      direction={"row"}
                      // justifyContent={"center"}
                      alignItems={"center"}
                      sx={{ width: "100%" }}
                    >
                      <Typography
                        variant="body1"
                        component="p"
                        sx={{
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          textOverflow: "ellipsis",
                        }}
                      >
                        {pmrSummary ||
                          summaryContent?.consultation_summary?.summary ||
                          (summaryContent?.summary !== "N/A" &&
                            summaryContent?.summary) ||
                          "No Summary Found"}
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack>
                      {formData && !isError ? (
                        <Typography>
                          Generating Summary Notes, Please wait
                          <DotsAnimationContainer className={"dotsAnimation"} />
                        </Typography>
                      ) : isError ? (
                        <Typography>
                          Error while generating audio. Please try again.
                        </Typography>
                      ) : !isRecording ? (
                        <Typography>
                          Record your conversation with patient to get a summary
                          of the conversation{" "}
                        </Typography>
                      ) : isRecording && !isActive ? (
                        <Typography>Recording paused</Typography>
                      ) : (
                        <Typography>
                          Recording
                          <DotsAnimationContainer className={"dotsAnimation"} />
                        </Typography>
                      )}
                    </Stack>
                  )}
                </AccordionDetails>
                <AccordionActions>
                  {isRecording ? (
                    <>
                      <Typography
                        variant="h3"
                        fontWeight={"500"}
                        sx={{ color: "gray", marginRight: "10px" }}
                      >
                        {formatTime(time)}
                      </Typography>
                      {isStart ? (
                        <Button
                          variant="outlined"
                          sx={{
                            border: "2px solid",
                            padding: "10px 20px",
                            fontWeight: 600,
                            textTransform: "none",
                          }}
                          onClick={() => {
                            pauseRecording();

                            setIsActive(false);
                            setIsStart(false);
                          }}
                        >
                          Pause Recording
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          sx={{
                            border: "2px solid",
                            padding: "10px 20px",
                            fontWeight: 600,
                            textTransform: "none",
                          }}
                          onClick={() => {
                            resumeRecording();

                            setIsActive(true);
                            setIsStart(true);
                          }}
                        >
                          Continue Recording
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        onClick={() => {
                          stopRecording();
                          handleReset();
                        }}
                        sx={{
                          border: "2px solid #1976d2",
                          padding: "10px 20px",
                          fontWeight: 600,
                          textTransform: "none",
                        }}
                      >
                        Generate Note
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="outlined"
                      sx={{
                        border: "2px solid",
                        padding: "10px 20px",
                        fontWeight: 600,
                        textTransform: "none",
                      }}
                      onClick={() => {
                        startRecording();
                        setIsRecording(true);
                        setIsStart(true);
                        setIsActive(true);
                        notify();
                        setIsError(false);
                      }}
                    >
                      Start Recording
                    </Button>
                  )}

                  {formData && !isRegenerating && !isRecording && (
                    <Button
                      sx={{
                        border: "2px solid",
                        padding: "10px 20px",
                        fontWeight: 600,
                        textTransform: "none",
                      }}
                      variant="outlined"
                      onClick={handleRegenerateAudio}
                      disabled={isRecording}
                    >
                      {isRegenerating ? "Regenerating" : "Regenerate"}
                    </Button>
                  )}
                </AccordionActions>
                {hospital?.hip_id === "123123" && (
                  <div style={{ display: "inline-flex", float: "right" }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>SOAP</Typography>
                      {/* <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} /> */}
                      <Switch
                        checked={isSOAPresponse}
                        onChange={handleResponseStructure}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <Typography>PMR &nbsp;&nbsp;</Typography>
                    </Stack>

                    <Select
                      style={{ width: "150px", marginRight: "8px" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={languageModel}
                      onChange={handleLanguageModelChange}
                    >
                      <MenuItem value="gemma2-9b-it">gemma2-9b-it</MenuItem>
                      <MenuItem value="llama3-70b-8192">
                        llama3-70b-8192
                      </MenuItem>
                      <MenuItem value="llama3-8b-8192">llama3-8b-8192</MenuItem>
                      <MenuItem value="mixtral-8x7b-32768">
                        mixtral-8x7b-32768
                      </MenuItem>
                      <MenuItem value="gpt-4-turbo">gpt-4-turbo</MenuItem>
                    </Select>
                  </div>
                )}
              </Accordion>
              {timeStamp && (
                <Stack
                  sx={{ height: "20px" }}
                  justifyContent={"flex-end"}
                  direction={"row"}
                  mt={1}
                >
                  <IconButton onClick={handlePositiveFeedBack}>
                    <ThumbUpAltIcon
                      sx={{
                        fontSize: "20px",
                        color: response === "true" ? "green" : "default",
                      }}
                    />
                  </IconButton>{" "}
                  <IconButton onClick={() => setOpenResDialog(true)}>
                    <ThumbDownIcon
                      sx={{
                        fontSize: "20px",
                        color: response === "false" ? "red" : "default",
                      }}
                    />
                  </IconButton>
                </Stack>
              )}
            </Stack>
          </>
        )}
        onStop={handleStopRecording}
      />
      {openSummary && (
        <Stack>
          <CustomizedSummaryDialog
            open={openSummary}
            setOpen={setOpenSummary}
            summaryContent={summaryContent}
            setSummaryContent={setSummaryContent}
          />
        </Stack>
      )}

      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openResDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Enter Your Feedback
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Enter Your Feedback Reason"
              variant="outlined"
              name="feeback_reason"
              value={resFeedData.feeback_reason}
              onChange={handleResponse}
              required
              multiline
              margin="dense"
            />
            <TextField
              fullWidth
              label="Enter Your Comment"
              variant="outlined"
              name="feedback_comment"
              value={resFeedData.feedback_comment}
              onChange={handleResponse}
              multiline
              required
              margin="dense"
            />
            <Button
              sx={{ marginTop: "10px" }}
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default RecorderComponent;
