import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, BASE_URL } from "../../utils/request";
import { apis } from "../../utils/apis";
import axios from "axios";

export const registerDoctor = createAsyncThunk(
  "doctorRegistration/registerDoctor",
  async (payload) => {
    const response = await apiRequest("POST", apis?.registerDoctor, payload);
    return response;
  }
);

export const updateDoctor = createAsyncThunk(
  "doctorRegistration/updateDoctor",
  async (payload) => {
    const response = await apiRequest("POST", apis?.updateDoctor, payload);
    return response;
  }
);
export const uploadProfile = createAsyncThunk(
  "doctorRegistration/updateDoctor",
  async ({ payload, formData }) => {
    const access_token = sessionStorage.getItem("accesstoken");
    try {
      const response = await axios.post(
        `${BASE_URL}/${apis?.uploadProfileImage}?doc_id=${payload?.doc_id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      throw Error(error.response.data.message);
    }
  }
);

const doctorRegistrationSlice = createSlice({
  name: "doctorRegistration",
  initialState: {
    loading: false,
    error: null,
    registeringDoctor: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerDoctor.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerDoctor.fulfilled, (state, action) => {
        state.loading = false;
        state.registeringDoctor = action.payload;
      })
      .addCase(registerDoctor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to register doctor";
      })
      // Handling updateDoctor actions
      .addCase(updateDoctor.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDoctor.fulfilled, (state, action) => {
        state.loading = false;
        // Assuming you want to update the registeringDoctor with the response
        state.registeringDoctor = action.payload;
      })
      .addCase(updateDoctor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to update doctor";
      });
  },
});

export default doctorRegistrationSlice.reducer;
