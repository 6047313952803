import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Autocomplete,
  Box,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputBase,
  InputLabel,
  TextField,
  createTheme,
  Card,
  Grid,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { alpha, styled } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import { useDispatch } from "react-redux";
import {
  generateAiNotes,
  updatePMRSummary,
} from "../../pages/DoctorPage/EMRPage/EMRPage.slice";
import CustomLoader from "../CustomLoader";
import CustomizedDialogs from "../Dialog";
import { min } from "date-fns";
import { useEffect } from "react";
import { decodeHashData } from "../../utils/hashing";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&.Mui-disabled": {
            WebkitTextFillColor: "black",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&.Mui-disabled": {
            WebkitTextFillColor: "black",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: 1,
          },
        },
      },
    },
  },
});

const CustomDialog = styled(Dialog)(({ theme }) => ({}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function CustomizedSummaryDialog({
  open,
  setOpen,
  summaryContent,
  setSummaryContent,
  emrData,
}) {
  const [showLoader, setShowLoader] = useState(false);
  const [translatedContent, setTranslatedContent] = useState(summaryContent);
  const [changeLanguage, setChangeLanguage] = useState(false);
  const [edit, setEdit] = useState(false);
  const [pmrDialogOpen, setPmrDialogOpen] = useState(false);
  const timeStamp = decodeHashData("timeStamp");
  const dispatch = useDispatch();
  const [suggestion, setSuggestion] = useState(null);

  useEffect(() => {
    if (timeStamp) {
      setShowLoader(true);
      const payload = {
        pmr_id: decodeHashData("pmrID"),
        transcript_timestamp: timeStamp,
      };
      dispatch(generateAiNotes(payload)).then((res) => {
        setSuggestion(res?.payload);
        setShowLoader(false);
      });
    }
  }, [timeStamp]);

  let content = !changeLanguage ? summaryContent : translatedContent;
  useEffect(() => {
    console.log("content");
  }, [changeLanguage, summaryContent, translatedContent]);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (translatedContent && translatedContent.length > 0) {
    }
  }, [translatedContent]);

  const handleSummaryChange = (event, index) => {
    const { name, value } = event.target;
    setSummaryContent((prevContent) => {
      let newContent = { ...prevContent };
      if (newContent[index] && newContent[index][1]) {
        newContent[index][1] = { ...newContent[index][1], [name]: value };
      }
      return newContent;
    });
  };

  const handleChipChange = (e, index, newValue, sectionName) => {
    setSummaryContent((prevContent) => {
      // Create a deep copy of the previous content
      const newContent = prevContent.map((item, idx) => {
        if (idx === index && item[1]) {
          return [
            ...item.slice(0, 1),
            {
              ...item[1],
              [sectionName]: newValue,
            },
          ];
        }
        return item;
      });
      return newContent;
    });
  };

  //Changing the Summary
  const handleSavechanges = () => {
    setShowLoader(true);
    const payload = {
      pmr_id: decodeHashData("pmrID"),
      summaryContent,
    };
    dispatch(updatePMRSummary(payload)).then((res) => {
      if (res?.payload) {
        setShowLoader(false);
        setEdit(!edit);
      }
    });
  };

  return (
    <React.Fragment>
      <CustomLoader open={showLoader} />
      <CustomizedDialogs
        open={pmrDialogOpen}
        handleClose={() => setPmrDialogOpen(false)}
      />
      <ThemeProvider theme={theme}>
        <CustomDialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Toolbar sx={{ borderBottom: "1px solid black" }}>
            <DialogTitle sx={{ flex: 1 }} id="customized-dialog-title">
              <Typography sx={{ fontWeight: 500 }} variant="h6">
                CLINICAL NOTE SUMMARY
              </Typography>
            </DialogTitle>
            <IconButton
              edge="start"
              color="primary"
              onClick={handleClose}
              aria-label="close"
              disabled={edit}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>

          <DialogContent>
            {suggestion && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box mb={2}>
                    <Grid container spacing={2}>
                      {/* Left Part */}
                      <Grid item xs={12} sm={5}>
                        <Typography variant="h6" gutterBottom>
                          Probing Questions
                        </Typography>
                        {suggestion?.notes_for_doctor?.probing_questions.map(
                          (item, index) => (
                            <Accordion sx={{ marginBottom: "5px" }} key={index}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                              >
                                {item}
                              </AccordionSummary>
                            </Accordion>
                          )
                        )}
                      </Grid>
                      {/* Right Part */}
                      <Grid
                        item
                        xs={12}
                        sm={6.8}
                        ml={2}
                        sx={{ backgroundColor: "#f9f9f9" }}
                        padding={"10px"}
                      >
                        <Box mb={2}>
                          <Typography variant="h6" gutterBottom>
                            Suggestions for Patient
                          </Typography>
                          {suggestion?.notes_for_patient?.suggestions.map(
                            (item, index) => (
                              <Card
                                key={index}
                                sx={{ padding: 1, marginBottom: 1 }}
                              >
                                <Typography>{item}</Typography>
                              </Card>
                            )
                          )}
                        </Box>
                        <Box mb={2}>
                          <Typography variant="h6" gutterBottom>
                            LifeStyle Guidance
                          </Typography>
                          {suggestion?.notes_for_patient?.lifestyle_modifications.map(
                            (item, index) => (
                              <Card
                                key={index}
                                sx={{ padding: 1, marginBottom: 1 }}
                              >
                                <Typography>{item}</Typography>
                              </Card>
                            )
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            )}

            <Accordion sx={{ marginBottom: 2 }}>
              <AccordionSummary
                alignItems={"center"}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h6" textAlign={"center"}>
                  <strong>Consultation Summary</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    disabled={!edit}
                    label="Consultation Summary"
                    variant="outlined"
                    defaultValue={
                      content?.consultation_summary?.summary ||
                      "NO Summary Available"
                    }
                    id="bootstrap-input"
                    name="summary"
                    multiline
                    onChange={(e) => handleSummaryChange(e, 0)}
                  />
                </FormControl>
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{ marginBottom: 2 }}>
              <AccordionSummary
                alignItems={"center"}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h6" textAlign={"center"}>
                  <strong>Subjective</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    <Typography variant="h6">
                      <strong> Chief Complaint: </strong>
                    </Typography>
                  </InputLabel>
                  <BootstrapInput
                    disabled={!edit}
                    defaultValue={
                      content?.subjective?.chief_complaint || "Not Available"
                    }
                    id="bootstrap-input"
                    multiline
                    name="chief_complaint"
                    onChange={(e) => handleSummaryChange(e, 1)}
                  />
                </FormControl>
                <>
                  <div>
                    <Typography gutterBottom>Allergy Information:</Typography>
                    <Autocomplete
                      multiple
                      freeSolo
                      disabled={!edit}
                      name="allergy_information"
                      onChange={(e, newValue) =>
                        handleChipChange(e, 1, newValue, "allergy_information")
                      }
                      id="tags-filled"
                      options={[]}
                      value={content?.subjective?.allergy_information || []}
                      clearOnBlur={true}
                      renderTags={(value, getTagProps) =>
                        value.map((item, index) => (
                          <Chip
                            variant="outlined"
                            label={item}
                            key={index} // Use index as key if items are unique
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Allergies"
                          onBlur={(e) => {
                            const { value } = e.target;
                            if (value.trim() !== "") {
                              handleChipChange(
                                e,
                                1,
                                [
                                  ...(content?.subjective
                                    ?.allergy_information || []),
                                  value.trim(),
                                ],
                                "allergy_information"
                              );
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === ",") {
                              const { value } = e.target;
                              if (value.trim() !== "") {
                                handleChipChange(
                                  e,
                                  1,
                                  [
                                    ...(content?.subjective
                                      ?.allergy_information || []),
                                    value.trim(),
                                  ],
                                  "allergy_information"
                                );
                                e.target.value = ""; // Clear input after adding chip
                              }
                            }
                          }}
                        />
                      )}
                    />
                  </div>

                  <div>
                    <Typography gutterBottom>Family History:</Typography>
                    <Autocomplete
                      multiple
                      freeSolo
                      disabled={!edit}
                      name="family_history"
                      onChange={(e, newValue) =>
                        handleChipChange(e, 1, newValue, "family_history")
                      }
                      id="tags-filled"
                      options={[]}
                      value={content?.subjective?.family_history || []}
                      clearOnBlur={true}
                      renderTags={(value, getTagProps) =>
                        value.map((item, index) => (
                          <Chip
                            variant="outlined"
                            label={item}
                            key={index} // Use index as key if items are unique
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Family History"
                          onBlur={(e) => {
                            const { value } = e.target;
                            if (value.trim() !== "") {
                              handleChipChange(
                                e,
                                1,
                                [
                                  ...(content?.subjective?.family_history ||
                                    []),
                                  value.trim(),
                                ],
                                "family_history"
                              );
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === ",") {
                              const { value } = e.target;
                              if (value.trim() !== "") {
                                handleChipChange(
                                  e,
                                  1,
                                  [
                                    ...(content?.subjective?.family_history ||
                                      []),
                                    value.trim(),
                                  ],
                                  "family_history"
                                );
                                e.target.value = ""; // Clear input after adding chip
                              }
                            }
                          }}
                        />
                      )}
                    />
                  </div>

                  <div>
                    <Typography gutterBottom>
                      History of Present Illness:
                    </Typography>
                    <Autocomplete
                      multiple
                      freeSolo
                      disabled={!edit}
                      name="history_of_present_illness"
                      onChange={(e, newValue) =>
                        handleChipChange(
                          e,
                          1,
                          newValue,
                          "history_of_present_illness"
                        )
                      }
                      id="tags-filled"
                      options={[]}
                      value={
                        content?.subjective?.history_of_present_illness || []
                      }
                      clearOnBlur={true}
                      renderTags={(value, getTagProps) =>
                        value.map((item, index) => (
                          <Chip
                            variant="outlined"
                            label={item}
                            key={index} // Use index as key if items are unique
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="History of Illness"
                          onBlur={(e) => {
                            const { value } = e.target;
                            if (value.trim() !== "") {
                              handleChipChange(
                                e,
                                1,
                                [
                                  ...(content?.subjective
                                    ?.history_of_present_illness || []),
                                  value.trim(),
                                ],
                                "history_of_present_illness"
                              );
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === ",") {
                              const { value } = e.target;
                              if (value.trim() !== "") {
                                handleChipChange(
                                  e,
                                  1,
                                  [
                                    ...(content?.subjective
                                      ?.history_of_present_illness || []),
                                    value.trim(),
                                  ],
                                  "history_of_present_illness"
                                );
                                e.target.value = ""; // Clear input after adding chip
                              }
                            }
                          }}
                        />
                      )}
                    />
                  </div>

                  <div>
                    <Typography gutterBottom>Medication History:</Typography>
                    <Autocomplete
                      multiple
                      freeSolo
                      disabled={!edit}
                      name="medication_history"
                      onChange={(e, newValue) =>
                        handleChipChange(e, 1, newValue, "medication_history")
                      }
                      id="tags-filled"
                      options={[]}
                      value={content?.subjective?.medication_history || []}
                      clearOnBlur={true}
                      renderTags={(value, getTagProps) =>
                        value.map((item, index) => (
                          <Chip
                            variant="outlined"
                            label={item}
                            key={index} // Use index as key if items are unique
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Medication History"
                          onBlur={(e) => {
                            const { value } = e.target;
                            if (value.trim() !== "") {
                              handleChipChange(
                                e,
                                1,
                                [
                                  ...(content?.subjective?.medication_history ||
                                    []),
                                  value.trim(),
                                ],
                                "medication_history"
                              );
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === ",") {
                              const { value } = e.target;
                              if (value.trim() !== "") {
                                handleChipChange(
                                  e,
                                  1,
                                  [
                                    ...(content?.subjective
                                      ?.medication_history || []),
                                    value.trim(),
                                  ],
                                  "medication_history"
                                );
                                e.target.value = ""; // Clear input after adding chip
                              }
                            }
                          }}
                        />
                      )}
                    />
                  </div>

                  <div>
                    <Typography gutterBottom>Past Medical History:</Typography>
                    <Autocomplete
                      multiple
                      freeSolo
                      disabled={!edit}
                      name="past_medical_history"
                      onChange={(e, newValue) =>
                        handleChipChange(e, 1, newValue, "past_medical_history")
                      }
                      id="tags-filled"
                      options={[]}
                      value={content?.subjective?.past_medical_history || []}
                      clearOnBlur={true}
                      renderTags={(value, getTagProps) =>
                        value.map((item, index) => (
                          <Chip
                            variant="outlined"
                            label={item}
                            key={index} // Use index as key if items are unique
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Past Medical History"
                          onBlur={(e) => {
                            const { value } = e.target;
                            if (value.trim() !== "") {
                              handleChipChange(
                                e,
                                1,
                                [
                                  ...(content?.subjective
                                    ?.past_medical_history || []),
                                  value.trim(),
                                ],
                                "past_medical_history"
                              );
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === ",") {
                              const { value } = e.target;
                              if (value.trim() !== "") {
                                handleChipChange(
                                  e,
                                  1,
                                  [
                                    ...(content?.subjective
                                      ?.past_medical_history || []),
                                    value.trim(),
                                  ],
                                  "past_medical_history"
                                );
                                e.target.value = ""; // Clear input after adding chip
                              }
                            }
                          }}
                        />
                      )}
                    />
                  </div>

                  <div>
                    <Typography gutterBottom>Social History:</Typography>
                    <Autocomplete
                      multiple
                      freeSolo
                      disabled={!edit}
                      name="social_history"
                      onChange={(e, newValue) =>
                        handleChipChange(e, 1, newValue, "social_history")
                      }
                      id="tags-filled"
                      options={[]}
                      value={content?.subjective?.social_history || []}
                      clearOnBlur={true}
                      renderTags={(value, getTagProps) =>
                        value.map((item, index) => (
                          <Chip
                            variant="outlined"
                            label={item}
                            key={index} // Use index as key if items are unique
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Social History"
                          onBlur={(e) => {
                            const { value } = e.target;
                            if (value.trim() !== "") {
                              handleChipChange(
                                e,
                                1,
                                [
                                  ...(content?.subjective?.social_history ||
                                    []),
                                  value.trim(),
                                ],
                                "social_history"
                              );
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === ",") {
                              const { value } = e.target;
                              if (value.trim() !== "") {
                                handleChipChange(
                                  e,
                                  1,
                                  [
                                    ...(content?.subjective?.social_history ||
                                      []),
                                    value.trim(),
                                  ],
                                  "social_history"
                                );
                                e.target.value = ""; // Clear input after adding chip
                              }
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                </>
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{ marginBottom: 2 }}>
              <AccordionSummary
                alignItems={"center"}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h6">
                  <strong>Objective</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    <Typography variant="h6">
                      <strong> Laboratory and Diagnostic Test Result: </strong>
                    </Typography>
                  </InputLabel>
                  <BootstrapInput
                    disabled={!edit}
                    defaultValue={
                      content?.objective
                        ?.laboratory_and_diagnostic_test_results ||
                      "Not Available"
                    }
                    id="bootstrap-input"
                    multiline
                    name="laboratory_and_diagnostic_test_results"
                    onChange={(e) => handleSummaryChange(e, 2)}
                  />
                </FormControl>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    <Typography variant="h6">
                      <strong>Examination Findings </strong>
                    </Typography>
                  </InputLabel>
                  <BootstrapInput
                    disabled={!edit}
                    defaultValue={
                      content?.objective?.physical_examination_findings ||
                      "Not Available"
                    }
                    id="bootstrap-input"
                    multiline
                    name="physical_examination_findings"
                    onChange={(e) => handleSummaryChange(e, 2)}
                  />
                </FormControl>
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{ marginBottom: 2 }}>
              <AccordionSummary
                alignItems={"center"}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h6">
                  <strong>Assessment</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    <Typography variant="h6">
                      <strong> Differential Diagnosis : </strong>
                    </Typography>
                  </InputLabel>
                  <BootstrapInput
                    disabled={!edit}
                    defaultValue={
                      content?.assessment?.differential_diagnosis ||
                      "Not Available"
                    }
                    id="bootstrap-input"
                    multiline
                    name="differential_diagnosis"
                    onChange={(e) => handleSummaryChange(e, 3)}
                  />
                </FormControl>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    <Typography variant="h6">
                      <strong>Preliminary Diagnosis:</strong>{" "}
                    </Typography>
                  </InputLabel>
                  <BootstrapInput
                    disabled={!edit}
                    defaultValue={
                      content?.assessment?.preliminary_diagnosis ||
                      "Not Available"
                    }
                    id="bootstrap-input"
                    multiline
                    name="preliminary_diagnosis"
                    onChange={(e) => handleSummaryChange(e, 3)}
                  />
                </FormControl>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    <Typography variant="h6">
                      <strong>Risk Factors:</strong>{" "}
                    </Typography>
                  </InputLabel>
                  <BootstrapInput
                    disabled={!edit}
                    defaultValue={
                      content?.assessment?.risk_factors || "Not Available"
                    }
                    id="bootstrap-input"
                    multiline
                    name="risk_factors"
                    onChange={(e) => handleSummaryChange(e, 3)}
                  />
                </FormControl>
              </AccordionDetails>
            </Accordion>
          </DialogContent>
          <DialogActions gap={2}>
            {!edit ? (
              <Button
                sx={{
                  width: "50px",
                  "@media screen and (min-width: 0px) and (max-width: 600px)": {
                    paddingY: "17px",
                  },
                }}
                autoFocus
                variant="outlined"
                onClick={() => setEdit(!edit)}
                disabled={true}
              >
                Edit
              </Button>
            ) : (
              <Button
                sx={{ width: "150px" }}
                autoFocus
                variant="outlined"
                onClick={handleSavechanges}
              >
                Save Changes
              </Button>
            )}
          </DialogActions>
        </CustomDialog>
      </ThemeProvider>
    </React.Fragment>
  );
}
