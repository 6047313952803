import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import {
  Grid,
  Typography,
  styled,
  Card,
  CardActions,
  CardContent,
  Button,
  Paper,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { BarChart } from "@mui/x-charts/BarChart";
import { useDispatch } from "react-redux";
import {
  fetchAppointmentList,
  fetchAppointmentAnalystics,
} from "../AppointmentPage/AppointmentPage.slice";
import { convertDateFormat, parseDateFormat } from "../../utils/utils";
import SearchIcon from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CreateTempleteDialog from "./Section";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { tableCellClasses } from "@mui/material/TableCell";
import { getAllTemplates, getTemplateResponse } from "./template.slice";
import { decodeHashData, encodeHashData } from "../../utils/hashing";

const DashboardWrapper = styled("div")(({ theme }) => ({
  "&": {
    padding: "25px 10px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  ".patientList-title-wrapper": {
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  ".patientList-heading": {
    "&.MuiTypography-root": theme.typography.h1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },
  ".patientList-desc": theme.typography.h2,
  ".chart-wrapper": {
    backgroundColor: "#fff",
  },
  ".chartStyling": {
    width: "100%",
    height: "450px",
  },
  ".chartStyling .MuiChartsAxis-tickLabel": {
    fontSize: "16px !important",
  },
}));

const chartSetting = {
  yAxis: [
    {
      tickNumber: 0,
    },
  ],
  // xAxis: [
  //   {
  //   }
  // ],
};

const diagnosisTemplates = [
  {
    name: "fever",

    createdTime: new Date().toISOString(), // Current date and time in ISO format
  },
  {
    name: "cold",

    createdTime: new Date().toISOString(),
  },
  {
    name: "headache",

    createdTime: new Date().toISOString(),
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1976d2",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Dashboard = () => {
  const currentHospital = decodeHashData("selectedHospital");
  const username = decodeHashData("userName");
  const user_id = decodeHashData("user_id");
  const dispatch = useDispatch();
  const [appointmentAnalystics, setAppointmentAnalystics] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [templateRes, setTemplateRes] = useState([]);
  const [pendingAppointment, setPendingAppointment] = useState(0);
  const [followupCases, setFollowupCases] = useState(0);
  const [newCases, setNewCases] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [isChart, setIsChart] = useState(false);
  const isMobile = window.innerWidth < 600;
  const [searchTemplate, setSearchTemplate] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const [templateId, setTemplateId] = useState("");

  useEffect(() => {
    if (currentHospital) {
      const payload = {
        hip_id: currentHospital?.hip_id,
      };
      const dates = [];
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 6);
      for (let i = 0; i < 7; i++) {
        const date = currentDate.toLocaleDateString(undefined, {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        });
        const day = currentDate.toLocaleDateString(undefined, {
          weekday: "long",
        });
        const day_date = currentDate.toLocaleDateString(undefined, {
          weekday: "long",
          year: "numeric",
          month: "numeric",
          day: "numeric",
        });
        dates.push({
          date: parseDateFormat(date, "yyyy-MM-dd"),
          day: day,
          count: 0,
          day_date: day_date,
        });
        currentDate.setDate(currentDate.getDate() + 1);
      }
      dispatch(fetchAppointmentAnalystics(payload)).then((res) => {
        const mainList = res?.payload;

        setAppointmentAnalystics(mainList);
        // let appointment_days = [];
        // let finalArray;
        // mainList.map((list) => {
        //   if (list?.appointment_type === "first visit") {
        //     setNewCases((prevCount) => prevCount + 1);
        //   } else if (list?.appointment_type === "follow-up visit") {
        //     setFollowupCases((prevCount) => prevCount + 1);
        //   }
        //   if (list?.consultation_status === "Completed") {
        //     setCompletedAppointment((prevCount) => prevCount + 1);
        //   } else if (list?.consultation_status === "InProgress") {
        //     setPendingAppointment((prevCount) => prevCount + 1);
        //   }
        //   dates.map((date) => {
        //     if (date.date === list?.slot_details?.date) {
        //       appointment_days.push({ date: date.date, day: date.day });
        //     }
        //   });
        // });

        // const countsByDays = {};
        // appointment_days.forEach(({ day }) => {
        //   countsByDays[day] = (countsByDays[day] || 0) + 1;
        // });

        // finalArray = Object.entries(countsByDays).map(([day, count]) => ({
        //   day,
        //   count,
        // }));

        // const chart_data = dates.map((item) => {
        //   const matchedObject = finalArray.find((obj) => obj.day === item.day);
        //   return { ...item, ...matchedObject };
        // });
        // setChartData(chart_data);
        //setIsChart(true);
      });
    }
  }, []);

  useEffect(() => {
    const payload = {
      user_id: user_id,
    };
    dispatch(getAllTemplates(payload)).then((res) => {
      setTemplateRes(res?.payload);
    });
  }, [isRender]);

  const handleTemplete = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredTemplates = templateRes?.filter((template) =>
    template?.template_name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const handleGetTemplateDetail = (id) => {
    setTemplateId(id);
    const payload = {
      template_id: id,
    };

    dispatch(getTemplateResponse(payload)).then((res) => {
      setSearchTemplate(res?.payload);
      setShowDialog(true);
      setOpen(true);
      setEdit(true);
    });
  };

  return (
    <DashboardWrapper>
      <div className="patientList-title-wrapper">
        <div>
          <Typography className="patientList-heading">
            Hello, {username}
          </Typography>
          <Typography className="patientList-desc">
            Your Clinic Dashboard
          </Typography>
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Card style={{ height: "190px" }}>
            <CardContent>
              <Typography color="text.secondary" gutterBottom>
                Total number of appointments
              </Typography>
              <Typography variant="h5" component="div">
                <br />
              </Typography>
              <Typography variant="body2">
                <br />
              </Typography>
              <Typography variant="h1">
                {appointmentAnalystics.total_appointments}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card style={{ height: "190px" }}>
            <CardContent>
              <Typography color="text.secondary" gutterBottom>
                Completed appointments
              </Typography>
              <Typography variant="h5" component="div">
                {appointmentAnalystics.completed_appointments}
              </Typography>
              <br />
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                Pending appointments
              </Typography>
              <Typography variant="h5">
                {appointmentAnalystics.inprogress_appointments}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card style={{ height: "190px" }}>
            <CardContent>
              <Typography color="text.secondary" gutterBottom>
                Follow up cases
              </Typography>
              <Typography variant="h5" component="div">
                {appointmentAnalystics.follow_up_appointments}
              </Typography>
              <br />
              <Typography sx={{ mt: 1.5 }} color="text.secondary">
                New cases
              </Typography>
              <Typography variant="h5">
                {appointmentAnalystics.first_visit_appointments}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ backgroundColor: "white", my: 8 }} maxWidth={1000} padding={3}>
        <Typography variant="subtitle" fontSize={"2rem"} fontWeight={600}>
          Templates
        </Typography>
        <Stack direction={"row"} gap={3} mt={2} mb={2}>
          <TextField
            placeholder="Search Template"
            value={searchTerm}
            onChange={handleTemplete}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
          <CreateTempleteDialog
            searchTemplate={searchTemplate}
            setSearchTemplate={setSearchTemplate}
            templateId={templateId}
            open={open}
            setOpen={setOpen}
            edit={edit}
            setEdit={setEdit}
            isRender={isRender}
            setIsRender={setIsRender}
          />
        </Stack>
        <TableContainer component={Paper} sx={{ height: "350px" }}>
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#0089E9 !important" }}>
                <StyledTableCell sx={{ color: "white", fontSize: "17px" }}>
                  Id
                </StyledTableCell>
                <StyledTableCell sx={{ color: "white", fontSize: "17px" }}>
                  Template Name
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  sx={{ color: "white", fontSize: "17px" }}
                >
                  Created At
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  sx={{ color: "white", fontSize: "17px" }}
                >
                  Updated At
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTemplates?.map((row, index) => (
                <TableRow
                  key={index}
                  hover={true}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleGetTemplateDetail(row?.id);
                  }}
                >
                  <TableCell align="left">{row?.id}</TableCell>
                  <TableCell align="left">{row.template_name || " "}</TableCell>
                  <TableCell align="right">
                    {row.created_at.slice(0, 10)}
                  </TableCell>
                  <TableCell align="right">
                    {row.updated_at.slice(0, 10)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </DashboardWrapper>
  );
}

export default Dashboard;
